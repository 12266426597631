import { useState } from "react";

const Checkout = () => {
    const [empno, setEmpNo] = useState("");
    const [empname, setEmpName] = useState("");
    const [empemail, setEmpEmail] = useState("");
    let cartItems = JSON.parse(localStorage.getItem('userInfo') || '[]');

    
    let sum = 0;
    for (let x = 0; x < cartItems.length; x++) {
      sum += parseInt(cartItems[x].qtys) * parseInt(cartItems[x].Price);}

      function addEmpNo(e) {
        setEmpNo(e.target.value);
      }
    
      function addEmpName(e) {
        setEmpName(e.target.value);
      }
      function addEmpEmail(e) {
        setEmpEmail(e.target.value);
      }

      function addEmployee(e) {
        e.preventDefault();
        async function saveEmployee() {
            let x = 0;
            while (x < cartItems.length) {
              console.log(x);
              let sku = cartItems[x].Sku;
              let price = cartItems[x].Price;
              let qty = cartItems[x].qtys;

              const response = await fetch(
                  "https://bhenpp5.wd99p.com/db/index.php",
                  {
                    method: "POST",
                    mode: "cors",
                    body: JSON.stringify({
                      cust_name: empno,
                      cust_add: empname,
                      cust_phone: empemail,
                      pro_sku: sku,
                      pro_price: price,
                      pro_qty: qty
                    }),
                  }
                );

              x++;
            }

        }
    
        saveEmployee();
        localStorage.clear();
        alert("Thank you for your order \n You will receive message notification on your mobile number within 24 hrs");
        window.location.reload();
      }
  
      
    return (
        <section>
        <div class="container-sm d-flex flex-column flex-lg-row justify-content-around gap-class align-self-stretc p-3">
        <div class="col-lg-8 text-center cart-big">
            <h1 class="mt-5">Checkout Form</h1>
            {(() => {
              if (cartItems.length > 0){
                  return (
                    <div>
                    <table class="table mt-4"><thead><tr><th class='modal-th-2'>Product</th><th class='modal-th-3'>Amount</th><th class='modal-th-4'>Quantity</th><th class='modal-th-4'>Price</th></tr></thead><tbody>
                    {cartItems.map((cart, index) => (
                      <tr>
                        <td><img class="rounded-circle cart-photo"  src={cart.Photo}/></td>
                        <td>₱ {cart.Price}</td>
                        <td>{cart.qtys}</td>
                        <td>₱ {(parseInt(cart.Price)*parseInt(cart.qtys))}</td>
                      </tr>
                      ))}
                      <tr class="cart-table">
                        <td></td>
                        <td></td>
                        <td><h6>Total</h6></td>
                        <td>₱ {sum}</td>
                      </tr>
                      </tbody>

                      </table> 
                      <div class="order-form mt-4">
                      <form>
                            <label>Receiver Name : </label>
                            <input
                              className="form-control"
                              type="text"
                              value={empno}
                              onChange={(e) => addEmpNo(e)}
                            />{" "}
                            
                            <label>Receiver Phone : </label>
                            <input
                              className="form-control"
                              type="text"
                              value={empname}
                              onChange={(e) => addEmpName(e)}
                            />{" "}
                            
                            <label>Shipping Address : </label>
                            <input
                              className="form-control"
                              type="text"
                              value={empemail}
                              onChange={(e) => addEmpEmail(e)}
                            />{" "}
                            <button class="btn btn-dark mt-3" onClick={(e) => addEmployee(e)}>
                            Order Now
                            </button>
                        </form>
                      </div>
                      </div>
                  )
              }
              
              else{
                return (
                <div class="cart-box">
                <h3>Your cart is empty!</h3>
                </div>
            )
              }
            })()}


        </div>
        </div>
      </section>
    );
  };
  
  export default Checkout;
  