import { useEffect, useState } from "react";
import {Link, useNavigate} from 'react-router-dom';


const Cardsfetch = () => {
  const [Products, setProducts] = useState([]);


  async function fetchProducts() {
    const response = await fetch(
      "https://bhenpp5.wd99p.com/db/index.php"
    );
    const product = await response.json();
    setProducts(product.data);

    

  }
  fetchProducts();
  
  const navigate = useNavigate();
  async function openProduct(prodId) {
    navigate('/product' ,{state:{id:prodId}});

  }

  return (
    <section class="container-fluid">
      <div class="container-lg mt-4">
        <div class="row row-cols-2 row-cols-md-4">
          {Products.map((product) => (
            <div class="col">
              <div class="card collection-container">
                <img
                  src={product.photo}
                  class="card-img-top container-image"
                  alt="..."
                />
                <div class="middle">
                  <div class="text">
                    <button type="button" class="btn btn-dark" onClick={(e) => openProduct(product.id)}>
                      Browse Product 
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Cardsfetch;
