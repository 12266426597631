const Cart = () => {
    let cartItems = JSON.parse(localStorage.getItem('userInfo') || '[]');
  
    let sum = 0;
    for (let x = 0; x < cartItems.length; x++) {
      sum += parseInt(cartItems[x].qtys) * parseInt(cartItems[x].Price);}
      
  
  
      function minus(index) {
        let Qtys = cartItems[index].qtys;
      
        if (Qtys > 1){
        cartItems[index].qtys = parseInt(cartItems[index].qtys) - 1;
        cartItems.push(); 
        localStorage.setItem('userInfo', JSON.stringify(cartItems));
        }
        else {
          cartItems.splice(index, 1);
          localStorage.setItem('userInfo', JSON.stringify(cartItems));
        }
        window.location.reload()
      }
      function plus(index) {
        cartItems[index].qtys = parseInt(cartItems[index].qtys) + 1;
        cartItems.push(); 
        localStorage.setItem('userInfo', JSON.stringify(cartItems));
        window.location.reload()
      }

      
    return (
      <section>
        <div class="container-sm d-flex flex-column flex-lg-row justify-content-around gap-class align-self-stretc p-3">
        <div class="col-lg-8 text-center cart-big">
            <h1 class="mt-5">Your Cart</h1>
            {(() => {
              if (cartItems.length > 0){
                  return (
                    <div>
                    <table class="table mt-4"><thead><tr><th class='modal-th-2'>Product</th><th class='modal-th-3'>Amount</th><th class='modal-th-4'>Quantity</th><th class='modal-th-4'>Price</th></tr></thead><tbody>
                    {cartItems.map((cart, index) => (
                      <tr>
                        <td><img class="rounded-circle cart-photo"  src={cart.Photo}/></td>
                        <td>₱ {cart.Price}</td>
                        <td><button class="qty-button" onClick={(e) => minus(index)}>-</button>{cart.qtys}<button class="qty-button" onClick={(e) => plus(index)}>+</button></td>
                        <td>₱ {(parseInt(cart.Price)*parseInt(cart.qtys))}</td>
                      </tr>
                      ))}
                      <tr class="cart-table">
                        <td></td>
                        <td></td>
                        <td><h6>Total</h6></td>
                        <td>₱ {sum}</td>
                      </tr>
                      </tbody>

                      </table> 
                      <button type="button" class="btn btn-dark" onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "/checkout";
                    }}>Proceed to Checkout</button>
                      </div>
                  )
              }
              
              else{
                return (
                <div class="cart-box">
                <h3>Your Cart is Empty!</h3>
                </div>
            )
              }
            })()}


        </div>
        </div>
      </section>
    );
  };
  
  export default Cart;
  