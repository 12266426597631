import Slider from "./slider";
import Cards from "./cards";
import Newsletter from "./newsletter";
import Cardsfetch from "./cardsfetch";

function Home() {
  return (
    <div>
      <Slider />
      <Cardsfetch />
      <Newsletter />
    </div>
  );
}

export default Home;
