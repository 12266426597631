import logo from "../img/logo.png";
const Header = () => {
  let cartItems = JSON.parse(localStorage.getItem('userInfo') || '[]');
  
  let cartElement = cartItems.length;
  let sum = 0;
  for (let x = 0; x < cartItems.length; x++) {
    sum += parseInt(cartItems[x].qtys) * parseInt(cartItems[x].Price);}
    

  return (
    <header>
      <div class="container-fluid">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
              <a class="navbar-brand web-logo" href="/">
                <img src={logo} />
              </a>
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="/">
                      Home
                    </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Collections
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" href="/women-collections">
                          Women Collections
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" href="/men-collections">
                          Men Collections
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/about-us">
                      About us
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="/contact-us">
                      Contact us
                    </a>
                  </li>
                </ul>
                <form class="d-flex" role="search">
                  <input
                    class="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button class="btn btn-outline-dark" type="submit">
                    Search
                  </button>
                </form>
                <button type="button" id="btn-show-favorites" class="btn position-relative cart-button"
                      data-bs-toggle="modal" data-bs-target="#exampleModal"
                      >
                      <i class="fas fa-shopping-cart cart-style"></i>
                        <span id="spn-badge" class="position-absolute mt-1 top-0 start-100 translate-middle badge rounded-pill bg-danger">
                          {cartElement}
                        </span>
                      </button>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* Cart Modal */}
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Your Cart</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body table-responsive" id="modal-contents">
              {(() => {
              if (cartItems.length > 0){
                  return (
                    <table class='table'><thead><tr><th class='modal-th-2'>Product</th><th class='modal-th-3'>Amount</th><th class='modal-th-4'>Quantity</th><th class='modal-th-4'>Price</th></tr></thead><tbody>
                    {cartItems.map((cart, index) => (
                      <tr>
                        <td><img class="rounded-circle cart-photo"  src={cart.Photo}/></td>
                        <td>{cart.Price}</td>
                        <td>{cart.qtys}</td>
                        <td>{(parseInt(cart.Price)*parseInt(cart.qtys))}</td>
                      </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <td>Total</td>
                        <td>{sum}</td>
                      </tr>
                      </tbody>
                      </table>
                  )
              }
              
              else{
                return (
                <div class="cart-box-small">
                <h3>Your Cart is Empty!</h3>
                </div>
            )
              }
            })()}

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-dark" onClick={(e) => {
                      e.preventDefault();
                      window.location.href = "/cart";
                    }}>Open Cart</button>
              </div>
            </div>
          </div>
        </div>
    </header>
    
  );
};

export default Header;
