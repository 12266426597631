
import stars from "../../img/stars.png";
import {useLocation} from 'react-router-dom';
import { useEffect, useState } from "react";



const Women1 = () => {
  const [Product, setProduct] = useState([]);
  const location = useLocation();
  const prodId = location.state.id;
  async function fetchProduct() {

    const response = await fetch(
      "https://bhenpp5.wd99p.com/db/index.php?id="+prodId,

    );
    const producto = await response.json();
    setProduct(producto.data);
    
  }
  fetchProduct();
  // let CartItems = [];
  
  function addCart(proId, proSku, proName, proPhoto, proPrice) {
    let cartItems = JSON.parse(localStorage.getItem('userInfo') || '[]');

    let x = 0;
    let Found = false;
    while (x < cartItems.length) {
      if (cartItems[x].Prodid == prodId) {
        Found = true;
        break;
      }
      x++;
    }
  
    if (Found) {
      cartItems[x].qtys = !cartItems[x].qtys
        ? 1
        : parseInt(cartItems[x].qtys) + 1;
      cartItems.push(); 
      localStorage.setItem('userInfo', JSON.stringify(cartItems));
    } else {
      let userData = {
        Prodid: proId,
        Sku: proSku,
        Name: proName,
        Photo: proPhoto,
        Price: proPrice,
        qtys : 1
      }
      
      cartItems.push(userData); 
      localStorage.setItem('userInfo', JSON.stringify(cartItems));
      
    }
    window.location.reload()
   
  }

  return (
  
    <section class="mt-4 mb-4">
        {Product.map((product) => (
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-between gap-class align-self-stretc p-3">
        <div class="col-lg-5">
          <div
            id="carouselExampleAutoplaying"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src={product.photo} class="d-block w-100" alt="..." />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div>
            <h4>{product.productname}</h4>
            <img class="w-25" src={stars} />
            <h3>₱{product.productprice}</h3>
          </div>

          <div class="mt-3">
            <button type="button" class="btn btn-dark btn-lg w-50" onClick={(e) => addCart(product.id, product.sku, product.productname, product.photo, product.productprice)}>
              Add to Cart
            </button>
          </div>
          <div class="mt-3">
            <h6>Product Details</h6>
            <p>
            {product.productdescription}
            </p>
          </div>
        </div>
      </div>
          ))}
    </section>

  );
};

export default Women1;
