import slider1 from "../img/slide-1.jpg";
import slider2 from "../img/slide-2.jpg";
import slider3 from "../img/slide-3.jpg";
const Slider = () => {
  return (
    <section class="mt-2">
      <div
        id="carouselExampleCaptions"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={slider1} class="d-block w-100" alt="..." />
            <div class="carousel-caption d-flex flex-column align-items-start justify-content-center">
              <p class="carousel-header">Shop the collection.</p>
              <p class="carousel-content">
                Don’t stress about the dress, we’ll dress you to impress
              </p>
              <button class="btn btn-outline-dark btn-sm" type="submit">
                FIND OUT MORE
              </button>
            </div>
          </div>
          <div class="carousel-item">
            <img src={slider2} class="d-block w-100" alt="..." />
            <div class="carousel-caption d-flex flex-column align-items-start justify-content-center">
              <p class="carousel-header">Shop the collection.</p>
              <p class="carousel-content">
                Keep it simple, but make it memorable.
              </p>
              <button class="btn btn-outline-dark btn-sm" type="submit">
                FIND OUT MORE
              </button>
            </div>
          </div>
          <div class="carousel-item">
            <img src={slider3} class="d-block w-100" alt="..." />
            <div class="carousel-caption d-flex flex-column align-items-start justify-content-center">
              <p class="carousel-header">Shop the collection.</p>
              <p class="carousel-content">
                Life is too short to wear boring clothes.
                <br />
                So let's rock some fabulous outfits!
              </p>
              <button class="btn btn-outline-dark btn-sm" type="submit">
                FIND OUT MORE
              </button>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  );
};

export default Slider;
