const Footer = () => {
  return (
    <footer class="footdesign container-fluid">
      <div class="container-sm d-flex  flex-column flex-lg-row justify-content-end align-self-stretc p-4">
        <div class="col-lg-4">
          <h4>CONNECT WITH US</h4>
          <ul class="ulfooter">
            <li class="nav-item nav-link">
              <a
                class="footlink"
                aria-current="page"
                href="https://twitter.com/"
              >
                <i class="fa-brands fa-twitter social-color"></i> Follow us on
                twitter{" "}
              </a>
            </li>
            <li class="nav-item nav-link">
              <a
                class="footlink"
                aria-current="page"
                href="https://pinterest.com/"
              >
                <i class="fa-brands fa-pinterest social-color"></i> Pin us on
                Pinterest
              </a>
            </li>
            <li class="nav-item nav-link">
              <a
                class="footlink"
                aria-current="page"
                href="https://facebook.com/"
              >
                <i class="fa-brands fa-facebook social-color"></i> Like us on
                Facebook
              </a>
            </li>
            <li class="nav-item nav-link">
              <a
                class="footlink"
                aria-current="page"
                href="https://instagram.com/"
              >
                <i class="fa-brands fa-instagram social-color"></i> Follow us on
                Instagram
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <h4>SHOP</h4>
          <ul class="ulfooter">
            <li class="nav-item nav-link">
              <a class="footlink" aria-current="page" href="/women-collections">
                Women Collections
              </a>
            </li>
            <li class="nav-item nav-link">
              <a class="footlink" aria-current="page" href="/men-collections">
                Men Collections
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <h4>CUSTOMER SERVICE</h4>
          <ul class="ulfooter">
            <li class="nav-item nav-link">
              <a class="footlink" aria-current="page" href="#">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
