const Newsletter = () => {
  return (
    <section>
      <div class="container-fluid newsletter mt-4 p-4">
        <div class="container-sm d-flex flex-column align-items-center p-3">
          <div>
            <h4 class="text-center">Subscribe to our newsletter</h4>
          </div>
          <div>
            <p>Get updates and special offers from FashionShop by Bhen</p>
          </div>
          <div class="form-newsletter">
            <input type="email" class="form-control w-50" placeholder="Email" />
          </div>
          <div>
            <button
              class="btn btn-dark mt-2 subs-btn"
              onclick="window.location.href='#';"
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
