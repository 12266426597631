const Aboutus = () => {
  return (
    <section>
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-around gap-class align-self-stretc p-3">
        <div class="col-lg-8 text-center">
          <h2>
            <strong>About Us</strong>
          </h2>
          <p>&nbsp;</p>
          <p>
            La Mode Men’s provides fashion for men who want to look their best,
            but who don’t want to pay exorbitant prices. It is for men who take
            pride in style, class and elegance, but who have a social conscience
            and a desire to help those less fortunate. La Mode men are
            innovators and they’re leaders, and they want fashion that empowers
            their ambition as they move forward in life. La Mode men are sharply
            dressed free thinkers.
          </p>
          <p>
            La Mode Men’s is an exciting new concept in men’s online fashion
            retailing, blending impeccable style, top quality clothing,
            affordable prices, and a strong commitment to helping the community.
          </p>
          <p>
            Our range of men’s clothing includes ties, dress shirts and socks,
            and it is constantly expanding. Everything that features in our
            store is handpicked by our men’s fashion experts for its quality of
            material and manufacture, and its style statement. And because of
            our excellent relationships with suppliers we can negotiate the best
            prices, which we then pass on to you.
          </p>
          <p>
            Men’s fashion is in our blood, but it does not make up our whole
            being as we also have a strong social conscience and a commitment to
            helping the communities where we live and work. We give a percentage
            of every sale that we make on La Mode Men’s directly to local
            homeless shelters. In addition we provide clothing, free of charge,
            to people living in shelters to help them go on job interviews and
            get back on track with their lives.
          </p>
          <h2>
            <strong>Our Mission</strong>
          </h2>
          <p>&nbsp;</p>
          <p>La Mode Men’s helps men look good, and we help the community.</p>
          <p>
            It is a simple mission that allows us to focus on what is truly
            important – providing you with the best range of fashion possible,
            and giving back as much as we can for the benefit of others.
          </p>
          <p>
            We update our range regularly to take account of changing seasons
            and developing styles. Plus we offer regular promotions, helping you
            save even more.
          </p>
          <p>
            La Mode Men’s is a family-run business. We also operate the women’s
            online retail store La Mode. We are big enough to bring you the best
            in men’s fashion, but because we are a family-run business we place
            emphasis on personal-touch customer service that is not available
            from faceless corporate retailers. Call us, email us, ask us a
            question, make a comment – we are always happy to hear from you, and
            will help in whatever way we can.
          </p>
          <p>
            You deserve to look sharp. You deserve to look your best. You
            deserve La Mode Men’s.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
