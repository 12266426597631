import product5 from "../img/product/men1.jpg";
import product6 from "../img/product/men2.jpg";
import product7 from "../img/product/men3.jpg";
import product8 from "../img/product/men4.jpg";

const Mencol = () => {
  return (
    <section class="container-fluid mb-4">
      <div class="d-flex justify-content-center flex-column align-items-center mt-4">
        <h3>Mens Collections</h3>
      </div>
      <div class="container-lg mt-4">
        <div class="row row-cols-2 row-cols-md-4">
          <div class="col">
            <div class="card collection-container">
              <img
                src={product5}
                class="card-img-top container-image"
                alt="..."
              />
              <div class="middle">
                <div class="text">
                  <button type="button" class="btn btn-dark">
                    Browse Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card collection-container">
              <img
                src={product6}
                class="card-img-top container-image"
                alt="..."
              />
              <div class="middle">
                <div class="text">
                  <button type="button" class="btn btn-dark">
                    Browse Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card collection-container">
              <img
                src={product7}
                class="card-img-top container-image"
                alt="..."
              />
              <div class="middle">
                <div class="text">
                  <button type="button" class="btn btn-dark">
                    Browse Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card collection-container">
              <img
                src={product8}
                class="card-img-top container-image"
                alt="..."
              />
              <div class="middle">
                <div class="text">
                  <button type="button" class="btn btn-dark">
                    Browse Product
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mencol;
