const Contact = () => {
  return (
    <section>
      <div class="d-flex justify-content-center flex-column align-items-center mt-4">
        <h4>Contact Us</h4>
        <p>We would like to hear from you!</p>
      </div>
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-around gap-class align-self-stretc p-3">
        <div class="col-lg-5">
          <form>
            <div class="mb-3">
              <label class="col-form-label">Name:</label>
              <input
                type="text"
                class="form-control"
                id="recipient-name"
                placeholder="Enter your First Name"
              />
            </div>
            <div class="mb-3">
              <label class="col-form-label">Last Name:</label>
              <input
                type="text"
                class="form-control"
                id="recipient-name"
                placeholder="Enter your Last Name"
              />
            </div>
            <div class="mb-3">
              <label class="col-form-label">Email:</label>
              <input
                type="text"
                class="form-control"
                id="recipient-name"
                placeholder="Enter your Email"
              />
            </div>
            <div class="mb-3">
              <label for="message-text" class="col-form-label">
                Message:
              </label>
              <textarea
                class="form-control contact-text-area"
                id="message-text"
              ></textarea>
              <div class="text-center">
                <button type="button" class="btn btn-dark mt-4">
                  Send message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-around gap-class align-self-stretc p-3">
        <div class="gmap">
          <iframe
            class="w-100 h-100"
            frameborder="0"
            src="https://www.google.com/maps/embed/v1/place?q=Butuan+City,+Agusan+Del+Norte,+Philippines&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
